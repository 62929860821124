<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0 justify-content-center">

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto py-2 shadow-lg rounded"
        >
          <b-link
            :href="SITE_URL"
          >
            <b-img
              :src="appLogoImage"
              fluid
              alt="Responsive image"
              class="center-logo mb-1  px-5"
            />
          </b-link>

          <b-card-title
            title-tag="h4"
            class="font-weight-bold mb-1 text-center "
          >
            Bienvenue sur {{ appName }}
          </b-card-title>

          <!-- form -->
          <validation-observer
            #default="{ invalid }"
            ref="loginValidation"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="submitLoginForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    size="sm"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    autofocus
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Mot de passe oublié?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="mot de passe"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      size="sm"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="form.remember_me"
                  name="checkbox-1"
                >
                  Se souvenir de moi
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                size="sm"
                :disabled="invalid"
              >
                Se connecter

                <b-spinner
                  v-if="process"
                  variant="light"
                  class="ml-1"
                  type="grow"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { computed, reactive, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useAuth from '@/composables/authService'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const {
      handleLogin, process,
    } = useAuth()
    const { appName, appLogoImage, SITE_URL } = $themeConfig.app
    const sideImg = ref(require('@/assets/images/banner-2.jpg'))
    // eslint-disable-next-line no-return-assign
    const imgUrl = computed(() => (store.state.appConfig.layout.skin === 'dark' ? sideImg.value = require('@/assets/images/banner-2.jpg') : sideImg.value))
    const passwordFieldType = ref('password')
    const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
    }

    const form = reactive({
      email: '',
      password: '',
      remember_me: false,
    })
    const loginValidation = ref('')
    const submitLoginForm = () => {
      loginValidation.value.validate().then(async success => {
        if (success) { handleLogin({ ...form }) }
      })
    }

    return {
      appName,
      SITE_URL,
      appLogoImage,
      imgUrl,
      form,
      process,
      submitLoginForm,
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
      loginValidation,
      required,
      email,
    }
  },
}
</script>
<style scoped>
.center-logo {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
